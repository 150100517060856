import http from '@/utils/request'

/**
 * 玩家游戏数据
 * @returns
 */
export const playerData = () => {
  return http.get('/game-data/player-data')
}

/**
 * 游戏列表
 * @returns
 */
export const gameList = () => {
  return http.get('/game-data/game-list')
}
